.dashboard {
  height: 100%;
  display: flex;
  flex: 1 0 auto;
  padding: 10px;
}

.speedometers {
    display: flex;
}

.speedometer {
  flex: 1;
  text-align: center;
  width: 30vw;
}

row:after {
  content: "";
  display: table;
  clear: both;
}

.chartColumn {
  float: left;
  width: 70vw;
}

.settingsColumn {
  float: left;
  width: 20vw;
  height: 75vh;
  overflow-y: auto;
  scrollbar-color: #7f7f7f white; /* thumb and track color */
  scrollbar-width: thin;
}

.columntext {
  text-align: center;
}

.header {
  text-align: center;
}

.loader {
  position: relative;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
