.item {
  display: table;
  margin-left: auto;
  margin-right: auto;
  width: 15vw;
  height: 3rem;
  padding: .5rem .5rem;
  background: white;
  border: 1px solid lightgray;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 3px;
}

.itemError {
  display: table;
  margin-left: auto;
  margin-right: auto;
  width: 15vw;
  height: 3rem;
  padding: .5rem .5rem;
  background: white;
  border: 1px solid red;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 3px;
}

.iteminnactive {
  display: table;
  margin-left: auto;
  margin-right: auto;
  width: 15vw;
  height: 3rem;
  padding: .5rem .5rem;
  background: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-bottom: 3px;
}

.group {
  display: flex;
}

.icons {
  display: flex;
}

.delete {
  cursor: pointer;
  margin-left: -3.2rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  width: 1.6rem;
  height: 1.6rem;
  display: table;
  text-align: center;
  border-radius: 20px;
}

.svg {
  vertical-align: middle;
}
.delete:hover{
  background-color: red;
}

.edit {
  cursor: pointer;
  margin-left: 0rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  width: 1.6rem;
  height: 1.6rem;
  display: table;
  text-align: center;
  border-radius: 20px;
}

.edit:hover {
  background-color: green;
}

.selected {
  border-color: blue;
}

.item:hover {
  border-color: green;
}

.input {
  border: none;
  height: 2em;
  width: 100%;
}

.input_name {
  display: inline-flex;
  align-self: center;
  vertical-align: middle;
  padding-left: .5rem;
  padding-right: .5rem;
  width: 70%;
}

.left {
  display: inline-flex;
  vertical-align: middle;
  align-self: center;
}

.center {
  display: inline-flex;
  align-self: center;
  vertical-align: middle;
  padding-left: .5rem;
  padding-right: .5rem;
  width: calc(100% - 30px - 16px);
}

.right {
  display: inline-flex;
  align-self: center;
  vertical-align: middle;
}
