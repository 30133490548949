.help {
  margin: 0 auto;
}
.paragraph {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin: 0;
  color: #409040;
  text-align: justify;
}

.mainContainer {
  margin-left: 50px;
}

.title {
  font-family: 'Lato', sans-serif;
  margin: 0;
  color: #409040;
  text-align: center;
}

.img {
  width: 35vw;
}
.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75vw;
}
