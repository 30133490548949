.sidebar {
  display: flex;
  height: 100%;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
}

.sidebar_inner {
  padding: 10px;
  background: #F0F0F0;
  width: 50px;
}

.sidebar_list {
  list-style-type: none;
  padding: 0;
  top: 50;
  position: fixed;
}

.sidebar_list_bottom {
  list-style-type: none;
  padding: 0;
  bottom: 0;
  position: fixed;
}

.svgIcon:hover {
  filter: invert(10%) sepia(40%) saturate(1700%) hue-rotate(95deg) brightness(55%) contrast(67%);
}
