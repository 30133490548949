.title {
  font-family: 'Lato', sans-serif;
  margin: 0;
  margin-right: 4em;
  color: #6772e5;
  text-align: center;
}

.subscribe {
  background: #e0efe3;
  color: white;
  padding: 3rem;
  margin: 0;
  width: 100%;
}

.error {
  color: red;
}

.checkbox {
  color: black;
}

.charge {
  color: #6772e5;
}


.subscriptionButtons {
  display: flex;
  gap: 14vw;
  justify-content: center;
}

.label {
  color: green;
}

.titleContainer {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.poweredByStripeIcon {
  margin-left: 4em;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.StripeElement::placeholder {
  color: #aab7c4;
}

.StripeElement:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

.button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}

.disabledButton {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: grey;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

.cardHolder {
  margin: auto;
  width: 520px;
  border: 3px solid #6772e5;
  padding: 10px;
  background-color: #f6f9fc;
}

.addtoslack{
  text-align: center;
}
.paragraph {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin: 0;
  color: #409040;
  text-align: justify;
}

.img {
  width: 35vw;
}
.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45vw;
}

.A {
  background: #e0efe3;
  color: white;
  padding: 3rem;
  margin: 0;
}

.B {
  background: #c7e2ed;
  padding: 3rem;
  margin: 0;
}

.C {
  background: #c7e2ed;
  padding: 3rem;
  margin: 0;
}

.B::after {
  content: "";
  position: relative;
  left: -3rem;
  /* padding * -1 */
  top: calc( 3rem - 4rem / 2);
  /* padding - height/2 */
  float: left;
  display: block;
  height: 4rem;
  width: calc(100vw - 1rem);
  background: hsla(0, 0%, 100%, 0.5);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 70 500 60' preserveAspectRatio='none'%3E%3Crect x='0' y='0' width='500' height='500' style='stroke: none; fill: %23e0efe3;' /%3E%3Cpath d='M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z' style='stroke: none; fill: %23c7e2ed;'%3E%3C/path%3E%3C/svg%3E");
  background-size: 100% 100%;
  margin: 0;
}

.A::after {
  content: "";
  position: relative;
  left: -3rem;
  /* padding * -1 */
  top: calc( 3rem - 4rem / 2);
  /* padding - height/2 */
  float: left;
  display: block;
  height: 4rem;
  width: calc(100vw - 1rem);
  background: hsla(0, 0%, 100%, 0.5);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 70 500 60' preserveAspectRatio='none'%3E%3Crect x='0' y='0' width='500' height='500' style='stroke: none; fill: %23c7e2ed;' /%3E%3Cpath d='M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z' style='stroke: none; fill: %23e0efe3;'%3E%3C/path%3E%3C/svg%3E");
  background-size: 100% 100%;
  margin: 0;
}

.adiv {
  font-family: monospace;
  font-size: 1.2rem;
  line-height: 1.2;
}

.svg {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
}
.container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden;
}

.loader {
  position: relative;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
