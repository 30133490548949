.title {
  font-family: 'Lato', sans-serif;
  margin: 0;
  color: #409040;
  text-align: center;
}
.error {
  text-align: center;
  color: red
}
.addtoslack{
  text-align: center;
}
.paragraph {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin: 0;
  color: #409040;
  text-align: justify;
}

.img {
  width: 35vw;
}
.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45vw;
}

.A {
  background: #e0efe3;
  color: white;
  padding: 3rem;
  margin: 0;
}

.B {
  background: #c7e2ed;
  padding: 3rem;
  margin: 0;
}

.C {
  background: #c7e2ed;
  padding: 3rem;
  margin: 0;
}

.B::after {
  content: "";
  position: relative;
  left: -3rem;
  /* padding * -1 */
  top: calc( 3rem - 4rem / 2);
  /* padding - height/2 */
  float: left;
  display: block;
  height: 4rem;
  width: calc(100vw - 1rem);
  background: hsla(0, 0%, 100%, 0.5);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 70 500 60' preserveAspectRatio='none'%3E%3Crect x='0' y='0' width='500' height='500' style='stroke: none; fill: %23e0efe3;' /%3E%3Cpath d='M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z' style='stroke: none; fill: %23c7e2ed;'%3E%3C/path%3E%3C/svg%3E");
  background-size: 100% 100%;
  margin: 0;
}

.A::after {
  content: "";
  position: relative;
  left: -3rem;
  /* padding * -1 */
  top: calc( 3rem - 4rem / 2);
  /* padding - height/2 */
  float: left;
  display: block;
  height: 4rem;
  width: calc(100vw - 1rem);
  background: hsla(0, 0%, 100%, 0.5);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 70 500 60' preserveAspectRatio='none'%3E%3Crect x='0' y='0' width='500' height='500' style='stroke: none; fill: %23c7e2ed;' /%3E%3Cpath d='M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z' style='stroke: none; fill: %23e0efe3;'%3E%3C/path%3E%3C/svg%3E");
  background-size: 100% 100%;
  margin: 0;
}

.adiv {
  font-family: monospace;
  font-size: 1.2rem;
  line-height: 1.2;
}

.svg {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
}
.container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden;
}
