html, body {
  margin: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
}

hr{
  margin: 0;
}

h1, p {
  font-family: Lato;
  margin: 0;
  color: white;
}

#root {
  background: white;
  height: 100vh;
}

.svgIcon:hover {
  filter: invert(10%) sepia(40%) saturate(1700%) hue-rotate(95deg) brightness(55%) contrast(67%);
}

.column {
  float: left;
  width: 30vw;
}
.columntext {
  text-align: center;
}

.box {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 26vw;
  height: 30vh;
  border: 2px solid lightgray;
}
.inputbox {
  width: 14vw;
}
.formbox {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  width: 26vw;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.button {
  display:inline-block;
  margin:0 0.3em 0.3em 0;
  border-radius:50%;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-weight:300;
  color:#FFFFFF;
  background-color:#4eb5f1;
  text-align:center;
  transition: all 0.2s;
}

.button:hover {
  background-color:#4095c6;
}
