.addtoslack{
  text-align: center;
}

.request_access {
  width: 100%;
}

.error {
  text-align: center;
  color: red
}

.paragraph {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin: 0;
  color: #409040;
  text-align: center;
}

.request_access_button {
  border-color: #c7cacd;
  border-style: solid;
  border-radius: 7px;
  border-width: 1px;
  height: 40px;
  width: 172px;
  background: white;
  color: -webkit-link;
  cursor: pointer;
}

.loader {
  position: relative;
  top: calc(15% - 4em);
  margin: 0 auto;
  width: 4em;
  height: 4em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
