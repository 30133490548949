.request_access {
  width: 100%;
}

.paragraph {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin: 0;
  color: #409040;
  text-align: center;
}

.request_access_button {
  border-color: #c7cacd;
  border-style: solid;
  border-radius: 7px;
  border-width: 1px;
  height: 40px;
  width: 172px;
  background: white;
  color: -webkit-link;
  cursor: pointer;
}
