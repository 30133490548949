.title {
  font-family: Lato;
  margin: 0;
  color: #409040;
}

.link {
  text-decoration: none;
  color: #409040;
}

.freeTrial {
  padding: 0 10px;
}

.navbar {
  display: flex;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
  box-sizing: border-box;
  height: 3.75rem;
  padding: .5rem 1rem;
  width: 100%;
}

.fixed_top {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 200;
}

.navbar_inner {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.navbar_items {
  align-items: center;
  display: flex;
  flex: 1 0 0;
}

.navbar_items_right {
  justify-content: flex-end;
}

.navbar_link {
  color: #1c1e21;
  cursor: pointer;
  font-weight: 500;
  padding: .25rem 1rem;
  text-decoration: none;
}

.navbar_logo {
  height: 100%;
  margin-right: .5rem;
}

.navbar_icon {
    display: inline-flex;
    align-self: center;
}

.navbar_icon svg, .icon img {
    height: 1em;
    width: 1em;
    fill: currentColor;
}

.navbar_icon_baseline svg, .icon img {
    top: .125em;
    position: relative;
}

.navbar_user_login {
}

.navbar_logout_button {
  border-color: #c7cacd;
  border-style: solid;
  border-radius: 7px;
  border-width: 1px;
  height: 40px;
  width: 172px;
  background: white;
  color: -webkit-link;
  cursor: pointer;
}
