body>div {
    height: 100%;
    display: flex;
    flex-direction: column;
}

*, html {
    box-sizing: border-box;
}

body {
    color: #1c1e21;
    font-family: system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,Helvetica,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
    font-size: 15px;
    line-height: 1.5;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    word-wrap: break-word;
}
