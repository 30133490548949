.settings {
    height: 100%;
    display: flex;
    flex: 1 0 auto;
    padding: 10px;
}

.settingsContainer {

}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.groupsettings {
  float: left;
  width: 15vw;
  height: 65vh;
}

.groupsettingsinnactive {
  float: left;
  width: 15vw;
  height: 65vh;
  opacity: 0.6;
  filter: grayscale(100%);
}

.mainContainer {
  margin-left: 50px;
}

.reinstall {
  text-align: center;
  display: flex;
  flex-direction: row;
}

.reinstallDescription {
  width: 77vw;
}

.angrySubscription {
  display: flex;
  flex-direction: row;
  margin-top: 10px;

}

.unhappyError {
  color: red;
}
.unhappyColumn {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.angrySubscriptionDescription{
  width: 71vw;
  text-align: center;
}

.titleRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.angrySubscriptionSwitchColumn {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.angrySubscriptionSwitchTop {
  height: 20px;
}

.angrySubscriptionSwitch {
  justify-content: center;
  display: flex;
}

.reinstallSlackButon {
  vertical-align: middle;
}

.columnUnhappy {
  float: left;
  width: 33vw;
  scrollbar-color: #7f7f7f white; /* thumb and track color */
  scrollbar-width: thin;
}

.column {
  float: left;
  width: 22vw;
  height: 65vh;
  overflow-y: auto;
  scrollbar-color: #7f7f7f white; /* thumb and track color */
  scrollbar-width: thin;
}

.helpModal:hover {
  cursor: pointer;
}

.unhappyColumnChannel {
   display: flex;
}
.columnUnhappyInnactive {
  float: left;
  width: 33vw;
  scrollbar-color: #7f7f7f white; /* thumb and track color */
  scrollbar-width: thin;
  opacity: 0.6;
  filter: grayscale(100%);
}

.columninnactive {
  float: left;
  width: 22vw;
  height: 65vh;
  overflow-y: auto;
  scrollbar-color: #7f7f7f white; /* thumb and track color */
  scrollbar-width: thin;
  opacity: 0.6;
  filter: grayscale(100%);
}

.column::-webkit-scrollbar {
  width: 7.5px;
}

.column::-webkit-scrollbar-track {
  background: white;
}

.column::-webkit-scrollbar-thumb {
  background: #409040;
  border-right: 1px solid white;
  border-radius: 20px;
}

.columntext {
  text-align: center;
}

.columnBigText {
  text-align: justify;
}

.box {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 26vw;
  height: 30vh;
  border: 2px solid lightgray;
}

.formbox {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  width: 26vw;
}

.inputbox {
  width: 14vw;
}

.button {
  display:inline-block;
  margin:0 0.3em 0.3em 0;
  border-radius:50%;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-weight:300;
  color:#FFFFFF;
  background-color:#4eb5f1;
  text-align:center;
  transition: all 0.2s;
}

.header {
  text-align: center;
}

.popuptext {
  text-align: justify;
  padding: 30px;
}

.popuplist {
  list-style-type: decimal;
}

.loader {
  position: relative;
  top: calc(15% - 4em);
  margin: 0 auto;
  width: 4em;
  height: 4em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
